import { http, createConfig, createStorage, fallback } from '@wagmi/vue';
import { base, mainnet } from '@wagmi/core/chains';
import { coinbaseWallet, walletConnect, injected } from '@wagmi/vue/connectors';

export const projectId = '8210413ca73c65db256bcfe94b8bc70a';

export const metadata = {
  name: 'daobase.ai',
  description: 'daobase ai',
  url: 'https://daobase.ai/mintdaobadge', // origin must match your domain & subdomain
  icons: ['https://daobase.ai/favicon.ico'],
};

export const smartWalletConfig = createConfig({
  // @ts-ignore
  chains: [base, mainnet],
  // storage: createStorage({ storage: localStorage, key: 'daobase.ai' }),
  transports: {
    [base.id]: http(),
    [mainnet.id]: http(),
  },
  connectors: [
    coinbaseWallet({
      appName: metadata.name,
      appLogoUrl: metadata.icons[0],
      // mobile coinbase wallet WalletLinkSigner
      // preference: 'smartWalletOnly',
    })
  ]
});
