<template></template>
<script setup>
import { onMounted, watch } from 'vue';
import mitt, { mittWalletSelectHide } from '@/libs/event';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/vue';
const $store = useStore();
import {
  useWeb3Modal,
  useWeb3ModalProvider,
  useWeb3ModalAccount,
  useDisconnect,
  useWeb3ModalState,
} from '@web3modal/ethers5/vue';
import { useStore } from 'vuex';
import { doSignin, useUserInfo } from '@/hooks';
import { projectId, metadata } from '@/config/smartWallet';
// import { base } from '@wagmi/core/chains';

const userInfo = useUserInfo();

const base = {
  chainId: 8453,
  name: 'Base',
  currency: 'ETH',
  explorerUrl: 'https://basescan.org/',
  rpcUrl: 'https://base.drpc.org',
};

const mainnet = {
  chainId: 1,
  name: 'Ethereum',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://eth.drpc.org',
};

const polygon = {
  chainId: 137,
  name: 'Polygon',
  currency: 'MATIC',
  explorerUrl: 'https://polygonscan.com',
  rpcUrls: 'https://polygon.drpc.org',
};

const optimism = {
  chainId: 10,
  name: 'Optimism',
  currency: 'ETH',
  explorerUrl: 'https://optimistic.etherscan.io',
  rpcUrls: 'https://optimism.drpc.org',
};

const arbitrum = {
  chainId: 42161,
  name: 'Arbitrum',
  currency: 'ETH',
  explorerUrls: 'https://arbiscan.io',
  rpcUrls: 'https://arbitrum.drpc.org',
};

const bsc = {
  chainId: 56,
  name: 'BNB Smart Chain',
  currency: 'BNB',
  explorerUrls: 'https://bscscan.com',
  rpcUrls: 'https://bsc.drpc.org',
};

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [base, mainnet, polygon, optimism, arbitrum, bsc],
  projectId,
  featuredWalletIds: [
    'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
    'ef333840daf915aafdc4a004525502d6d49d77bd9c65e0642dbaefb3c2893bef',
    '38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662',
    '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
    'e7c4d26541a7fd84dbdfa9922d3ad21e936e13a7a0e44385d44f006139e44d3b',
    '1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369',
  ],
  themeMode: 'dark',
  cacheProvider: true,
  enableAnalytics: false, // Optional - defaults to your Cloud configuration
});
// 4. Use modal composable
const modal = useWeb3Modal();
const provider = useWeb3ModalProvider();

const { address, chainId, isConnected } = useWeb3ModalAccount();
const { disconnect } = useDisconnect();
const { open, selectedNetworkId } = useWeb3ModalState();
const connectWallet = async () => {
  await modal.open();
  mittWalletSelectHide();
};

onMounted(() => {
  mitt.on('wallet-connect', () => {
    connectWallet();
  });
  mitt.on('wallet-connect-disconnect', async () => {
    if (isConnected.value) {
      await disconnect();
    }
  });
});

const getProviderAndSignIn = async () => {
  window.walletConnectProvider = provider.walletProvider.value;
  // localStorage.setItem(
  //   LOCALSTORAGE_WALLET_PROVIDER,
  //   JSON.stringify(provider.walletProvider.value),
  // );
  doSignin(
    $store,
    [address.value],
    false,
    true,
    $store.state.wallet.urlInviteId,
  );
};

watch(
  () => [isConnected.value, address.value, userInfo.value.isSignedIn],
  async () => {
    const res = isConnected.value;
    const addr = address.value;
    if (res && addr) {
      if (!userInfo.value.isSignedIn) {
        getProviderAndSignIn();
      }
    }
  },
);

watch(
  () => isConnected.value,
  () => {
    window.walletConnectProvider = provider.walletProvider.value;
  },
);
</script>
<style scoped></style>
